import React, { useState } from "react";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";

import Container from "react-bootstrap/Container";

import data from "./data.json";

function App() {
  const [result, setResult] = useState(0);

  const handleChange = (event) => {
    let mm = event.target.value * 10;
    let a = data.find((d) => d.id == mm);

    if (a) {
      setResult(a.value);
    } else {
      setResult(0);
    }
  };
  return (
    <div className="app">
      <Navbar className="header" bg="light" variant="light">
        <Navbar.Brand href="#" className="brand">
          គណនាចំណុះប្រេង
        </Navbar.Brand>
      </Navbar>

      <Container className="main">
        <Form>
          <div className="result">
            {result} {"L"}
          </div>

          <Form.Control
            type="number"
            placeholder="ចំណុះ (សង់ទីម៉ែត)"
            name="cm"
            onChange={handleChange}
            className="input"
          />
        </Form>
      </Container>
    </div>
  );
}

export default App;
